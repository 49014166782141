import React from 'react';
import AudioPlayer from './AudioPlayer';

export default function Media(props) {
    return (
        <div className="clip--container">
            <div className="clip--video">
                {
                    props.video !== `` &&
                <video id="video" key={props.video} src={props.video}
                    autoPlay
                    loop
                    muted
                    playsInline>
                    Your browser does not support HTML5 video.
                </video>
            }
            </div>
            <div className="clip--audio">
                <AudioPlayer
                    key={props.audio}
                    audioPath={props.audio} />
            </div>
            <div className='clip--text'>
                <p>{props.title}</p>
                <p>{props.description}</p>
                <p>{props.gameText}</p>
            </div>
        </div>
    )
}