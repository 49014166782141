import React from 'react';
import logo from './images/logo.png';

export default function Navbar(props) {
    return (
        <header>
            <a href="https://lomo.band">
                <img className="nav--icon" src={logo} alt="icon" />
            </a>
            <h1>LOMO</h1>
            <form className="nav--controls">
                {/*<input className="nav--search" type="text" placeholder="Search..." />*/}
                <div className="nav--button" onClick={props.previousPiece}>Previous</div>
                <div className="nav--button" onClick={props.nextPiece}>Next</div>
            </form>
        </header>
    );
}