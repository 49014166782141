import React, { useState, useEffect } from 'react';
import { isMobile, browserName } from "react-device-detect";

const useAudio = audioPath => {
  const [audio] = useState(new Audio(audioPath));
  const [playing, setPlaying] = useState(true);
//Does this keep piling up paused audio streams? Find out
  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio, playing]);

  const toggle = () => {
    setPlaying(!playing)
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing, audio]
  );


  useEffect(() => {
    audio.addEventListener('ended', () => {
      audio.currentTime = 0
      audio.play()
      setPlaying(true)
    })
  }, [audio]);

  return [playing, toggle]
}

const AudioPlayer = ({ audioPath }) => {
  const [playing, toggle] = useAudio(audioPath);
 
  return (
    <div className="clip--audiocontrols" onClick={toggle}>{playing ? 'PAUSE' : 'PLAY'}</div>
  )
}

export default AudioPlayer
