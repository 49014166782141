import React, { useState } from 'react';

import './App.css';
import Navbar from './Navbar';


import Media from './Media';
import data from './data.json';
//import { browserName } from 'react-device-detect';


function App() {


let localMedia = [
  ...new Map(data.map(item => [item["name"], item])).values()
]


  const [mediaCounter, setMediaCounter] = useState(0);


//These functions skip index 0 so the blank page only shows up once

  const nextPiece = () => {
    setMediaCounter(prev => {
      if (prev < (localMedia.length - 1)) {
        //changes font CSS variable here before setting state, maybe would be better off somewhere else?
        document.documentElement.style.setProperty('--chosen-font', localMedia[prev + 1].font)
        return prev + 1;
      }
      else
        //same thing here 
        document.documentElement.style.setProperty('--chosen-font', localMedia[0].font)
      return 1;
    })
  };

  const previousPiece = () => {
    setMediaCounter(prev => {
      if (prev !== 0 && prev !== 1) {
        //changes font CSS variable here before setting state, maybe would be better off somewhere else?
        document.documentElement.style.setProperty('--chosen-font', localMedia[prev - 1].font)
        return prev - 1;
      }
      else if (prev === 1) {
        return localMedia.length - 1;
      }
      else
        //same thing here 
        document.documentElement.style.setProperty('--chosen-font', localMedia[localMedia.length - 1].font)
      return localMedia.length - 1;
    })
  };

  return (
    <div className="App">
      <Navbar
        nextPiece={nextPiece}
        previousPiece={previousPiece}
      />
      <Media
        /*
      Once again, array item layout:
      0 - Title
      1 - Video Link
      2 - Audio Link
      3 - Font
      4 - Description
      5 - Game Depicted
        */
        title={localMedia[mediaCounter].name}
        video={localMedia[mediaCounter].video}
        audio={localMedia[mediaCounter].audio}
        description={localMedia[mediaCounter].description}
        gameText={localMedia[mediaCounter].gameText}
      />
    </div>
  );
};

export default App;
